import React from "react"
import { blogs } from "../../content/Blogs"
import ContactSection from "../../components/common/ContactSection"
import InnerBlogPage from "../../components/molecules/InnerBlogPage"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const slug = `sda-housing-crisis-in-australia`

const blogPost = blogs.find(post => post.slug === slug) // Find the blog post by slug

const SpecialistDisabilityAccommodationSupplyInAustralia = () => (
  <Layout>
    <Seo
      title="SDA Housing Crisis in Australia: Key Challenges & Solutions"
      description="Explore the current SDA housing crisis in Australia. Learn about key challenges and discover practical solutions to improve access and quality of SDA housing."
    />

    <InnerBlogPage post={blogPost} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default SpecialistDisabilityAccommodationSupplyInAustralia
